<template>
  <div>
    <CCard class="p-4">
      <CRow>
        <CCol lg="6">
          <h5>Payroll List ({{dataParams.total}})</h5>
        </CCol>
        <CCol lg="12">
          
          <CButton
            v-if="config.getPermission('payroll').create"
            size="sm"
            color="success"
            @click="newPayroll"
            class="float-lg-right"
          >
            <i class="fa fa-plus"></i> Create Payroll
          </CButton>
          <CButton
              v-if="config.getPermission('payroll').download"
              size="sm"
              style="float: right;"
              color="btn btn-outline-primary"
              @click="download"
               class="float-lg-right mr-2"
            >
              <i class="fa fa-download"></i> Download
            </CButton>

        </CCol>
      </CRow>

      <!-- <CRow class="mt-2">
        <CCol lg="2">
          <CInput  placeholder="Payrol No."/>
        </CCol>
        
        <CCol lg="2">
          <div class="form-inline">
            <Datepicker
            
             
              input-class="form-control bg-white mr-2"
              placeholder="Payroll Date From"
             
            >
            </Datepicker>
          </div>
        </CCol>
        <CCol lg="2">
          <Datepicker
           
            input-class="form-control bg-white"
            placeholder="Payroll Date To"
           
          >
          </Datepicker>
        </CCol>

       
        <CCol lg="4">
          <div style="text-align: left">
            <CButton
              v-if="config.getPermission('personnel_ledger').create"
              size="sm"
              style="margin-right: 80px"
              color="btn btn-outline-primary"
              @click="download"
            >
              <i class="fa fa-download"></i> Download
            </CButton>
          </div>
        </CCol>
      </CRow> -->

      <CRow>
   
        <CCol lg="12" md="12" sm="12">
          <div class="form-group mt-4">
            <CInputRadioGroup
            @update:checked="typeChanged"
              :options="[
                {
                  value: 'all',
                  label: 'All',                               
                },
                {
                  value: 'draft',
                  label: 'Draft',
                },
        
                {
                  value: 'approved',
                  label: 'Approved',
                },
                {
                  value: 'paid',
                  label: 'Paid',
                },
              ]"
              :checked.sync="dataParams.status"
              :inline="true"              
              required="true"
              
            />
          </div>
        </CCol>
        <CCol lg="12">
          <div class="table-responsive-sm table-responsive-md">
            <CDataTable
            :tableFilter="{ placeholder: 'Payroll No' }"
              pagination
              :itemsPerPage="10"
              itemsPerPageSelect
              :loading="isLoading"
              :items="payroll_data"
              :fields="fields"
              hover
              outlined
           
            >
              <template #status="{ item }">
                <td
                  style="padding: 5px; width: 100px; text-transform: uppercase"
                >
                  <CBadge
                    style="margin-top: 10px"
                    :color="getBadge(item.status)"
                    >{{ item.status }}</CBadge
                  >
                </td>
              </template>
              <template #action="{ item }">
                <td style="padding: 5px; width: 100px">
                  <CDropdown toggler-text="Select" size="sm" color="info">
                    <CDropdownItem
                      v-if="config.getPermission('payroll').view"
                      @click="viewModal(item)"
                      ><i class="fa fa-eye">View</i></CDropdownItem
                    >
  
                    <CDropdownItem
                      v-if="config.getPermission('payroll').delete"
                      @click="deleteItem(item)"
                      ><i class="fa fa-trash"> Delete</i></CDropdownItem
                    >
                  </CDropdown>
                </td>
              </template>
            </CDataTable>
            <!-- <CPagination
              :active-page.sync="currentPage"
              :pages="Math.ceil(dataList.total / 20)"
              :activePage="currentPage"
              @update:activePage="updatePage"
            /> -->
          </div>
        </CCol>
      </CRow>
    </CCard>
    
    
  </div>
</template>
<script>
import config from "../../config.js";
import axios from "../../axios";
import Swal from "sweetalert2";
// import Search from "./search";
import Datepicker from "vuejs-datepicker";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import moment from "moment";

export default {
  mounted() {
    this.getData();
  },
  filters: {
    formatDate(val) {
      if (val) return moment.unix(val).format("MMMM DD, YYYY");
      return "";
    },
    uppercase(val) {
      if (val) return val.toUpperCase();
      return "";
    },
    
  },
  watch: {
  },
  computed: {
   
  },
  data() {
    return {
      isLoading: false,
      config,
      dataParams: {
        status: "all",
        total:0,
      },
     
      // dataParams: {},

      formModal: false,
     
      dataList: [],
      depotName: "",            
      fields: [
        {
          key: "payroll_no",
          label: "Payroll No.",
        },
        {
          key: "date_created",
          label: "Date Created",
        },
        {
          key: "user_name",
          label: "Processed By",
        },
        {
          key: "payroll_period",
          label: "Payroll Period",
        },
        {
          key: "employee_no",
          label: "Employee No.",
        },
        {
          key: "employee_total_earnings",
          label: "Total Earnings",
        },
        {
          key: "employee_payment",
          label: "Total Net Pay",
        },
        
        {
          key: "status",
          label: "Status",
        },
        {
          key: "action",
          label: "Action",
        },
      ],
      currentPage: 1,

      editMode: false,
      selectedRow: {},
      showCollapse: false,

      filter: {
        adjustment_id: "",
        booking_no: "",
        plate_no: "",
        destination: "",
        personnel_id: "",
        delivery_date_from: "",
        delivery_date_to: "",
        status: "",
      },
      dataListHolder: [],

      personnelList: {
        data: [],
      },
      payroll_data: [
        // {
        //   payroll_no: "PN0001",
        //   date_created: "June 8 2022",
        //   payroll_period: "June 1 2022 - June 15 2022",
        //   employee_no: "6",
        //   employee_payment: "6000.00",
        //   employee_total_earnings: "6500.00",
        //   status: "Draft",
        // },
        // {
        //   payroll_no: "PN0002",
        //   date_created: "July 8 2022",
        //   payroll_period: "July 1 2022 - July 15 2022",
        //   employee_no: "8",
        //   employee_payment: "8000.00",
        //   employee_total_earnings: "8500.00",
        //   status: "Draft",
        // },
        // {
        //     payroll_no: "PN0003",
        //   date_created: "August 8 2022",
        //   payroll_period: "August 1 2022 - August 15 2022",
        //   employee_no: "10",
        //   employee_payment: "10000.00",
        //   employee_total_earnings: "10500.00",
        //   status: "Draft",
        // },
      ],
      
      
      
      is_seaching: false,
      dataListHolder: []
    };
  },
  name: "Payroll",
  components: {  Datepicker, vSelect },
  methods: {
    typeChanged(ev) {
      console.log(ev)
      if(ev == 'all') {
        this.payroll_data = [...this.dataListHolder];
      }
      else {
        this.payroll_data = this.dataListHolder.filter(item => item.status_type_holder == ev)
      }
    },
    getData() {
      this.$showLoading(true)
      axios.get(`${config.api_path}/payroll/payroll-list`).then(res => {
       console.log(res);
       this.dataParams.total = res.data.total;
       const {data,status} = res.data;                                         
        this.payroll_data = data.map(item => {
            item.employee_total_earnings = item.overall_earnings ? this.$options.filters.number_format(item.overall_earnings, 'currency', 2) : '';
            item.status_type_holder = item.status;
            item.employee_no = item.no_of_employee;
            item.employee_payment = item.overall_netpay ? this.$options.filters.number_format(item.overall_netpay, 'currency', 2) : '';                                                           
            item.payroll_period = moment(item.payroll_date_from).format('MMMM DD, YYYY')+' - '+moment(item.payroll_date_to).format('MMMM DD, YYYY');
            item.date_created = moment(item.created_at).format('MM/DD/YYYY'); 
            item.user_name = `${item.user.lastname}, ${item.user.firstname}`;
            return item;
        });
         this.$showLoading(false)
        this.dataListHolder = [...this.payroll_data];
        
      }).catch(err => {
        //this.$showLoading(true)
      })
    },
   
    download() {      
      this.$showLoading(true)
			axios.get(config.api_path+'/payroll/download-payroll-list')
			.then(response => {
				this.$showLoading(false)
				window.open(config.main_path.replace('/index.php', '')+'/'+response.data.file) 

					setTimeout(() => {
						axios.get(`${config.api_path}/remove-file`,{
							params : {
								filename : response.data.file
							}
						})	
					}, 2000);
				}).catch(err => {
				this.$showLoading(false)
			})
      
    },
 
  
   
    getBadge(status) {
      return status === "approved"
        ? "primary"
        : status === "paid"
        ? "success"
        : "warning";
    },
   
    addModal() {
      this.setMode("new");
      this.formModal = !this.formModal;
      this.title = "Add Personnel";
      this.resetParam();
    },
  
    
    viewModal(item) {
      console.log(item)
      if(item.status == 'draft' || item.status == 'approved' || item.status == 'paid') {
        window.open(`#/main/new_payroll/${item.id}`, '_blank');
      }
		// window.open(`#/main/view_payroll/${item.personnel_id}`, '_blank');
	},
    
   
    reloadData() {
      this.getData();
    },
    updatePage(data) {
      this.currentPage = data;
      this.getData();
    },

    search(event) {
      this.filter = event;
      this.getData();
    },

    
  
    deleteItem(item) {
      Swal.fire({
        icon: "warning",
        title: "Are you sure you want to delete this payroll?",
        showCancelButton: true,
        reverseButtons:true,
        confirmButtonText: `Yes`,
      }).then((result) => {
        if (result.isConfirmed) {
          axios
            .delete(config.api_path + `/payroll/delete/${item.id}`)
            .then((response) => {
              this.getData();
              Swal.fire({
                title: "Success!",
                text: "Payroll successfully deleted",
                icon: "success",
              });
            });
        }
      });
    },
    newPayroll() {
        window.open(`#/main/new_payroll/`, '_blank');
    }
  },
};
</script>
