var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('CCard',{staticClass:"p-4"},[_c('CRow',[_c('CCol',{attrs:{"lg":"6"}},[_c('h5',[_vm._v("Payroll List ("+_vm._s(_vm.dataParams.total)+")")])]),_c('CCol',{attrs:{"lg":"12"}},[(_vm.config.getPermission('payroll').create)?_c('CButton',{staticClass:"float-lg-right",attrs:{"size":"sm","color":"success"},on:{"click":_vm.newPayroll}},[_c('i',{staticClass:"fa fa-plus"}),_vm._v(" Create Payroll ")]):_vm._e(),(_vm.config.getPermission('payroll').download)?_c('CButton',{staticClass:"float-lg-right mr-2",staticStyle:{"float":"right"},attrs:{"size":"sm","color":"btn btn-outline-primary"},on:{"click":_vm.download}},[_c('i',{staticClass:"fa fa-download"}),_vm._v(" Download ")]):_vm._e()],1)],1),_c('CRow',[_c('CCol',{attrs:{"lg":"12","md":"12","sm":"12"}},[_c('div',{staticClass:"form-group mt-4"},[_c('CInputRadioGroup',{attrs:{"options":[
              {
                value: 'all',
                label: 'All',                               
              },
              {
                value: 'draft',
                label: 'Draft',
              },
      
              {
                value: 'approved',
                label: 'Approved',
              },
              {
                value: 'paid',
                label: 'Paid',
              } ],"checked":_vm.dataParams.status,"inline":true,"required":"true"},on:{"update:checked":[_vm.typeChanged,function($event){return _vm.$set(_vm.dataParams, "status", $event)}]}})],1)]),_c('CCol',{attrs:{"lg":"12"}},[_c('div',{staticClass:"table-responsive-sm table-responsive-md"},[_c('CDataTable',{attrs:{"tableFilter":{ placeholder: 'Payroll No' },"pagination":"","itemsPerPage":10,"itemsPerPageSelect":"","loading":_vm.isLoading,"items":_vm.payroll_data,"fields":_vm.fields,"hover":"","outlined":""},scopedSlots:_vm._u([{key:"status",fn:function(ref){
            var item = ref.item;
return [_c('td',{staticStyle:{"padding":"5px","width":"100px","text-transform":"uppercase"}},[_c('CBadge',{staticStyle:{"margin-top":"10px"},attrs:{"color":_vm.getBadge(item.status)}},[_vm._v(_vm._s(item.status))])],1)]}},{key:"action",fn:function(ref){
            var item = ref.item;
return [_c('td',{staticStyle:{"padding":"5px","width":"100px"}},[_c('CDropdown',{attrs:{"toggler-text":"Select","size":"sm","color":"info"}},[(_vm.config.getPermission('payroll').view)?_c('CDropdownItem',{on:{"click":function($event){return _vm.viewModal(item)}}},[_c('i',{staticClass:"fa fa-eye"},[_vm._v("View")])]):_vm._e(),(_vm.config.getPermission('payroll').delete)?_c('CDropdownItem',{on:{"click":function($event){return _vm.deleteItem(item)}}},[_c('i',{staticClass:"fa fa-trash"},[_vm._v(" Delete")])]):_vm._e()],1)],1)]}}])})],1)])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }